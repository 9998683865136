import React from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'

const PageWrap = styled.div`
max-width:960px;
margin:auto;
padding:3em 0;
`

const TCPost = ({ data }) => {
    const post = data.markdownRemark;
    return (
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
          <title>Marvel Studios Terms of Use</title>
          <meta property="og:title" content="Marvel Studios Terms of Use"></meta>
          <meta property="og:site_name" content="Marvel Studios Terms of Use"/>
          <meta property="og:url" content="https://www.marvelstudiostermsofuse.com"/>
          <meta property="og:description" content="Marvel Studios Terms of Use"/>
          <meta property="og:type" content="website"/>
          {post.frontmatter.image !== null ? <meta property="og:image" content={post.frontmatter.image.childImageSharp.original.src}/> : ''}
          <link rel="canonical" href="https://www.marvelstudiostermsofuse.com" />
        </Helmet>
        {post.frontmatter.image !== null ?
            <div style={{width: post.frontmatter.image.childImageSharp.original.width, margin: "0 auto" }}>
              <img src={post.frontmatter.image.childImageSharp.original.src} alt="Header" />
              </div>
        : ''
        }
            <PageWrap>
                <h1>{post.frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                {post.frontmatter.pdf.name !== "placeholder" ?
                 <div style={{display:"flex", justifyContent: "center"}}><a href={post.frontmatter.pdf.publicURL} rel="noopener noreferrer" target="_blank">Download PDF</a></div>
                 :
                ''}
            </PageWrap>
        </div>
    )
}

export default TCPost;

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/TermsConditions/TC1/" } }) {
      html
      frontmatter {
        title
        pdf{
          publicURL
          name
        }
        image {
          childImageSharp{
            original {
              src
              width
            }
          }
        }
      }
    }
  }
`;